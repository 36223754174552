import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import SEO from '../components/SEO.js';
import Home from '../components/Home';
import AppTheme from '../AppTheme.js';

const IndexPage = () => (
  <>
    <CssBaseline />
    <ThemeProvider theme={AppTheme}>
      <SEO title="Home" description="Something of a website." />
      <Home />
    </ThemeProvider>
  </>
);

export default IndexPage;
