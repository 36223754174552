import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import BackgroundImage from 'gatsby-background-image';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
    backgroundColor: "#1c1d1e",
  },
  backgroundImage: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
  title: {
    color: theme.palette.common.white,
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(10),
    },
    textShadow: `-2px -2px 5px ${theme.palette.common.black}`,
  },
  subtitle: {
    color: theme.palette.common.white,
    textShadow: `-2px -2px 5px ${theme.palette.common.black}`,
  },
  aLittleHidden: {
    opacity: 0.73,
  },
  credit: {
    '&:hover': {
      opacity: 1,
      textShadow: '-1px -1px 1px black, 1px 1px 1px black, -1px 1px 1px black, 1px -1px 1px black',
    },
    position: 'absolute',
    color: 'white',
    bottom: 0,
    right: 0,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    opacity: 0.05,
    textAlign: 'right',
  },
}));

const Home = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "ivana-cajina-asuyh-_ZX54-unsplash.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "ivana-cajina-asuyh-_ZX54-unsplash.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <BackgroundImage
      Tag="main"
      fluid={sources}
      className={clsx(classes.root, classes.backgroundImage)}
    >
      <Container maxWidth="md" component="section">
        <Typography variant="h2" component="h1" gutterBottom className={classes.title}>
          {`Ironforward`}
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom className={classes.subtitle}>
          {`Just a landing page`}
          <span className={classes.aLittleHidden}>
          {` ...for the most part.`}
        </span>
        </Typography>
      </Container>
      <Typography variant="caption" className={classes.credit}>
        {`Photo by Ivana Cajina on Unsplash`}
      </Typography>
    </BackgroundImage>
  );
};

export default Home;